























































































































































































































































































































































































































h3 {
  margin: 0;
  padding: 30px 20px 0;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--color-text-secondary);
  background-color: var(--color-surface);
  padding: 20px 26px;
  margin-top: 30px;
  width: 75%;
}

.profile-box-icon {
  display: flex;
  align-items: center;
  height: 34px;
  margin: 20px 0;
}

.input-container-size {
  width: 100%;
}

.align-profile-img {
  display: flex;
  align-items: center;
  margin: 29px 0 35px;
}

.text-profile {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000010;
}

.text-prifle-blue {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #0080B6;
  text-decoration: none;
}

i {
  font-size: 16px;
  color: #00add8;
}

.icon-profile {
  margin-right: 13px;
  font-size: 50px;
  color: #000010;
}

.checkboxes-container {
  display: flex;
  flex-direction: column;
}

.checkboxes-container span {
  color: var(--color-text-primary);
}

.mdc-select--outlined {
  margin: 29px 0 47px;
}

button {
  margin: 5px;
}

.input-container-size {
  margin: 20px 0;
}

.select-container-size {
  margin: 20px 0;
}

.profile-margin-checkbox {
  margin-left: 15px;
}

.profile-text-small {
  margin: 0 8px 15px;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
}

.profile-text-link {
  text-decoration: none;
  color: #00ADD9;
}

.profile-icon {
  cursor: pointer;
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell--span-8 {
    grid-column-end: span 6;
  }
}

@media (min-width: 1280px) {
  .mdc-layout-grid__cell--span-8 {
    grid-column-end: span 4;
    margin: 0 50px;
  }
}






.profile-section-header {
  background-color: #f3f4f7;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.profile-section-parent {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.helper-input-parent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.helper-input {
  font-size: 12px;
  color: var(--color-text-primary);
}

.profile-input {
  width: 80%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #00000044;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 10px;
  line-height: 35px;
  background-color: var(--color-surface);
  color: var(--color-text-secondary);
}

.profile-text-disabled {
  background-color: var(--color-surface);
  color: var(--color-text-secondary);
  border: 0 none;
}

.profile-text-disabled:hover {
  cursor: not-allowed;
}

.profile-input-disabled-parent {
  display: flex;
  align-items: center;
}

.help-icon {
  font-size: 20px;
  color: #00add9;
}

.pp-icon {
  font-size: 60px;
  color: black;
}

.pp-container {
  display: flex;
  align-items: center;
}

.pp-subcontainer {
  display: flex;
  flex-direction: column;
}

.pp-dialog-text {
  color: #1F7AA0;
  font-weight: bold;
}

@media (min-width: 901px) {
  .profile-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //background-color: cornflowerblue;
    height: 100%;
    align-items: center;
    margin: 50px;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 1279px) {
  #my-profile {
    margin-left: 50px !important;
  }
}

.profile-button {
  background-color: #00add9;
  border-radius: 20px;
  padding: 10px 50px;
  height: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 60%;
}

.profile-button:hover {
  background-color: #00a3cc;
  border-radius: 20px;
  padding: 10px 50px;
  height: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
}

.profile-section-parent {
  row-gap: 10px;
}

.profile {
  font-family: "Open Sans", sans-serif;
}

.helper-checkbox-parent input {
  accent-color: #009fc8;
}

.helper-checkbox-parent span {
  font-size: 14px;
}

.helper-checkbox-parent {
  margin-bottom: 10px;
}

.terminos {
  font-size: 12px;
  color: var(--color-text-primary);
}

.terminos a {
  color: #00add9;
  cursor: pointer;
  margin-bottom: 10px;
}

.LOPD-parent {
  margin-bottom: 0;
}

.checkbox-bottom-helper-parent {
  margin-bottom: 10px;
}

#profile-fail-load {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;
}

#profile-fail-load h1 {
  font-size: 20px;
}

#profile-fail-load span {
  color: #00add9;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
